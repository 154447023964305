// Navigation
;(() => {
  const el = document.querySelector('.nav')
  const button = document.querySelector('.button--nav')
  let isOpen = false

  const open = () => {
    el.classList.add('nav--open')
    button.classList.add('button--nav-open')
    isOpen = true
  }

  const close = () => {
    el.classList.remove('nav--open')
    button.classList.remove('button--nav-open')
    isOpen = false
  }

  button.addEventListener('click', () => (isOpen ? close() : open()))
})()
